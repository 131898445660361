import './src/styles/global.css';
import * as React from "react";
import { MDXProvider } from "@mdx-js/react"
import CodeBlock from './src/components/codeBlock'
// const MyParagraph = props => (
//   <p className="text-lg pb-2" {...props} />
// )
// const MyH1 = props => <h1 className="text-4xl pb-4" {...props} />
// const MyH2 = props => <h2 className="text-3xl pb-3" {...props} />
// const MyH3 = props => <h3 className="text-2xl pb-4" {...props} />
// const MyH4 = props => <h4 className="text-xl pb-3" {...props} />
const MyHref = props => <a className="underline text-bright transition duration-500 ease-in-out transform hover:text-dark hover:bg-light" {...props} />
// // const MyUl = props => <ul className="text-lg pb-2 list-disc list-inside" {...props} />
// const MyOl = props => <ol className="text-lg pb-2 list-decimal list-inside" {...props} />


const components = {
  // p: MyParagraph,
  // h1: MyH1,
  // h2: MyH2,
  // h3: MyH3,
  // h4: MyH4,
  // // ul: MyUl,
  // ol: MyOl,
  a: MyHref,
  pre: props => <div {...props} />,
  code: CodeBlock,
  inlineCode: props => (
    <span className="text-lg pb-1 pt-1 bg-gray-300 rounded-md pl-1 pr-1" {...props} />
  ),
  // li: props => <p className="text-xl pb-2" {...props} />
//   img: ({children, ...props}) => <div className="p-2" {...props}>{children}</div>,
}

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
)